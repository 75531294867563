<template>
  <div>
    <Top title="处理完成" />
    <div class="main-box pt44">
      <div class="report border-radius">
        <a-alert
          message="确认并记录该事项的最终处理成果，发起人验收后，可作为案例的改善说明"
          banner
        />
        <a-form-model
          layout="vertical"
          ref="reportForm"
          :model="form"
          :rules="rules"
          class="mt10"
        >
          <a-form-model-item ref="advice" class="form-item" prop="advice">
            <label class="iconfont icon-hps-appjianyijiqiwang"
              ><span>成果描述</span></label
            >
            <a-input
              v-model="form.advice"
              type="textarea"
              @change="expectInput"
              :auto-size="{ minRows: 2 }"
              :maxLength="200"
              @blur="
                () => {
                  $refs.advice.onFieldBlur();
                }
              "
              placeholder="请输入此事项处理成果的描述（案例可展示）"
            />
            <span class="right-tip">{{ expectLength }}/200</span>
          </a-form-model-item>
          <a-form-model-item class="form-item" ref="operateDesc">
            <label class="iconfont icon-hps-appjianyijiqiwang"
              ><span>备注</span></label
            >
            <a-input
              v-model="form.operateDesc"
              type="textarea"
              @change="operateDescLengthInput"
              :auto-size="{ minRows: 2 }"
              :maxLength="200"
              @blur="
                () => {
                  $refs.operateDesc.onFieldBlur();
                }
              "
              placeholder="请输入此事项处理完成的备注说明（处理过程记录）"
            />
            <span class="right-tip">{{ operateDescLength }}/200</span>
          </a-form-model-item>
          <a-form-model-item class="form-item pd">
            <label class="iconfont icon-hps-apptupian"
              ><span>上传图片</span></label
            >
            <!-- <div class="upload-img">
            <a-button type="dashed" size="large"
              ><a-icon type="plus" class="icon-color"
            /></a-button>
          </div> -->
            <div class="upload-img">
              <a-upload
                :action="VUE_APP_API_UPLOAD_URL + '/file/uploadFile'"
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
                accept="image/*"
                capture="camera"
                :remove="removeFile"
                :headers="token"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" class="icon-color" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="handle-btn border-top">
        <a-button class="save" @click="cancel">取消</a-button>
        <a-button class="submit" @click="submitForm">提交验收</a-button>
      </div>
    </div>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import Top from "@/components/top/top";
import { FormModel, Input, Button, Icon, Upload, Alert } from "ant-design-vue";
import { handleSuccess } from "@/utils/api";
export default {
  components: {
    AFormModel: FormModel,
    AFormModelItem: FormModel.Item,
    AButton: Button,
    AInput: Input,
    AIcon: Icon,
    AUpload: Upload,
    AAlert: Alert,
    Top,
  },
  mounted() {
    let token = localStorage.getItem("accessToken") || "";
    this.token = {
      Authorization: token,
    };
  },
  created() {},
  data() {
    return {
      VUE_APP_API_UPLOAD_URL: process.env.VUE_APP_API_UPLOAD_URL,
      token: {},
      id: this.$route.query.id,
      feReceipt: "",
      expectLength: 0,
      operateDescLength: 0,
      form: {
        advice: "",
        pictureName: "",
        pictureUrl: "",
        operateDesc: "",
      },
      previewVisible: false,
      uploading: false,
      previewImage: "",
      fileList: [],
      rules: {
        advice: [
          {
            required: true,
            message: "请输入处理方法和处理说明",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  methods: {
    removeFile(file) {
      this.fileList = [];
      this.form.pictureUrl = "";
      this.form.pictureName = "";
      this.uploading = false;
    },
    cancel() {
      this.$router.go("-1");
      localStorage.removeItem("feReceipt");
    },
    //保存
    async handleSuccess() {
      if (this.uploading) {
        this.baseConfirmLoading = false;
        this.$message.warning("图片尚未成功上传，请等待！");
        return;
      }
      const { resp_code, resp_msg } = await handleSuccess({
        id: this.id,
        operateDesc: this.form.operateDesc,
        handleDesc: this.form.advice,
        handlePictureName: this.form.pictureName,
        handlePictureUrl: this.form.pictureUrl,
      });
      if (resp_code === 0) {
        this.baseConfirmLoading = false;
        this.$message.success("操作成功", 2, () => {
          this.$router.push("/order?index=2");
        });
      } else {
        this.$message.error(resp_msg);
        this.baseConfirmLoading = false;
      }
    },
    submitForm() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          // this.visible = true;
          this.handleSuccess();
        } else {
          console.log(valid);
        }
      });
    },
    //预期文字长度
    expectInput() {
      let length = this.form.advice.length;
      if (length > 200) {
        this.expectLength = 200;
        return;
      }
      this.expectLength = length;
    },
    //预期文字长度
    operateDescLengthInput() {
      let length = this.form.operateDesc.length;
      if (length > 200) {
        this.operateDescLength = 200;
        return;
      }
      this.operateDescLength = length;
    },
    //隐藏上传图片
    handleCancel() {
      this.previewVisible = false;
    },
    //上传图片预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      // const isJpgOrPng =
      //   file.type === "image/jpeg" || file.type === "image/png";
      // if (!isJpgOrPng) {
      //   this.$message.error("You can only upload JPG file!");
      // }
      const isLt2M = file.size / 1024 / 1024 < 10;
      this.isLt2M = isLt2M;
      if (!isLt2M) {
        this.$message.error("请选择小于10M的图片!");
      }
      // return isJpgOrPng && isLt2M;
      return isLt2M;
    },
    //上传图片
    handleChange(info) {
      //info.file.response
      let response = info.file.response;
      if (response && response.message == "success") {
        let data = response.data;
        this.form.pictureName = data.fileName;
        this.form.pictureUrl = process.env.VUE_APP_API_BASE_URL + data.url;
        this.uploading = false;
      } else {
        this.uploading = true;
      }
      if (this.isLt2M) {
        this.fileList = info.fileList;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.report {
  position: relative;
  top: 8px;
  background: #fff;
  padding: 18.5px 3.99% 0 3.99%;
  .iconfont {
    font-size: 1.6rem;
    span {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }
  }
  .form-item {
    margin-bottom: 1.8rem !important;
    .ant-input {
      margin-top: 1.5rem;
    }
  }

  .form-select {
    // width: 50%;
    text-align: right;
    border: 0;
    position: absolute;
    right: 0;
    outline: 0;
    background: none;
  }
  .icon-color {
    color: #909399 !important;
    font-size: 1.8rem;
  }
  .upload-img {
    margin-top: 1.5rem;
    .ant-btn-lg,
    .ant-upload.ant-upload-select-picture-card {
      width: 6rem !important;
      height: 6rem !important;
    }
  }
  .right-tip {
    color: #bdbdbd;
    font-size: 1.2rem;
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}
</style>